import React from "react"
import { Router } from "@reach/router"
import BaseLayout from "../../components/Layouts/BaseLayout"
import Profile from "../../components/Profile/Profile"
import Login from "../../components/Login/Login"
import PrivateRoute from "../../components/PrivateRoute/PrivateRoute"

const App = () => (
  <BaseLayout>
    <Router>
      <PrivateRoute path="/app/profile" component={Profile} />
      <Login path="/app/login" />
    </Router>
  </BaseLayout>
)

export default App