import React from 'react';
import Helmet from 'react-helmet';

import Band from '../Band/Band';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Row from '../Grid/Row';
import Column from '../Grid/Column';

import 'sanitize.css';
import '../../styles/main.css';

export default ({ children, sidebar }) => (
    <div>

        <Helmet defaultTitle={'Headless WP With Gatsby'} titleTemplate={`%s | ${'Headless WP With Gatsby'}`} />

        <Header name={'Headless WP With Gatsby'} description={'Testing out Headless WP With Gatsby'} />

        <main className="main" role="main" id="main">

            <Band>
                {sidebar ? (
                    <Row>
                        <Column sm="8">
                            {children}
                        </Column>
                        <Column sm="4">
                            {sidebar}
                        </Column>
                    </Row>
                ) : (
                    children
                )}
            </Band>
        
        </main>

        <Footer name={'Headless WP With Gatsby'} />

    </div>
);
